import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

function IntroPhoto() {
  //   const [click, setClick] = useState(false);
  //   const handleClick = () => setClick(!click);
  //   const closeMenu = () => setClick(false);

  function MainButton1(props) {
    return (
      <div class="col-lg-6 button-left">
        <button type="button" class="btn btn-dark btn-lg download-button">
          <a href={props.hash} class="text-color-white">
            {props.buttonStr}
          </a>
        </button>
      </div>
    );
  }

  return (
    <div>
      <section>
        <div class="div-photo" id="">
          <div id="">
            <img id="photo5" src="./images/improv_photo5.png"></img>
          </div>
          <div>
            {/* <h3 id="home-font-a" class="txt-static">
              Funnest Improv in the DMV
            </h3> */}
            <div>
              <h2 id="home-font" class="txt-static">
                Fun and Educational Team Building Workshops
              </h2>
              {/* <h3 id="home-font-b">
                Fun and Educational Team Building Workshops
              </h3> */}
            </div>
            {/* <ul id="home-font2" class="txt-dynamic">
              <li>
                <span>Imagination</span>
              </li>
              <li>
                <span>Spontaneity</span>
              </li>
              <li>
                <span>Genius</span>
              </li>
              <li>
                <span>Inner Poet</span>
              </li>
              <li>
                <span>Listening</span>
              </li>
              <li>
                <span>Communication</span>
              </li>
              <li>
                <span>Collaboration</span>
              </li>
            </ul> */}

            {/* <div id="action-buttons">
              <div class="row">
                <MainButton1
                  buttonStr="For Individuals"
                  hash="#section-upcomingEvents"
                />
                <MainButton1
                  buttonStr="For Team Building"
                  hash="#section-orgImprov"
                />
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default IntroPhoto;
