import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  function FooterButton(props) {
    return (
      <div>
        <a class="footer-link" href={props.hash}>
          {props.buttonStr}
        </a>
      </div>
    );
  }

  return (
    <div>
      <section>
        <footer>
          <a class="footer-link" href="/">
            Home
          </a>{" "}
          {/* |
          <Link class="footer-link" to="/Blog">
            {" "}
            Blog
          </Link> */}
          <p>Copyright @ {currentYear} Kaboom Improv</p>
        </footer>
      </section>
    </div>
  );
}

export default Footer;
