import React from "react";
import { Link } from "react-router-dom";

function Excellence() {
  return (
    <div>
      <section id="section-upcomingEvents">
        <div id="div-upcomingEvents">
          <h1 class="center-text text-color-white">
            We're Built On Excellence
          </h1>
          <div id="div-events">
            <h3 class="aboutImprov-text center">
              Our teachers have taught over 500 students at the following
              places:
              <br />
              <br />
            </h3>
            <div class="row">
              <div class="col-lg-4">
                <div class="center-photo">
                  <img
                    id="logo-princeton"
                    src="./images/princeton.png"
                    alt="Princeton University"
                  ></img>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="center-photo">
                  <img
                    id="logo-moco"
                    src="./images/MOCO-libraries.png"
                    alt="Montgomery County Libraries"
                  ></img>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="center-photo">
                  <img
                    id="logo-wes"
                    src="./images/WES.png"
                    alt="Washington Ethical Society"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Excellence;
