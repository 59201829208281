import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";

import IntroPhoto from "./IntroPhoto";
import StartLine from "./StartLine";
import AboutImprov from "./AboutImprov";
import AboutImprov2 from "./AboutImprov2";
import UpcomingEvents from "./UpcomingEvents";
import Excellence from "./Excellence";
import OrgImprov from "./OrgImprov";
import AboutLili from "./AboutLili";
import Testimonials from "./Testimonials";
import Footer from "./Footer";

function Home() {
  return (
    <div>
      <IntroPhoto />
      <StartLine />
      <AboutImprov />
      <AboutImprov2 />
      <Excellence />
      <AboutLili />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
