import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  BrowserRouter,
} from "react-router-dom";

import Footer from "./Footer";

function ZBlog_CustomizedWorkshop(props) {
  function Br() {
    return (
      <div>
        <br />
      </div>
    );
  }
  return (
    <section class="section-BlogArticle">
      <div class="div-BlogArticle">
        <h2 class="center-text text-color-white">
          {" "}
          Example Customized Workshop
        </h2>
        <p class="p-BlogArticle text-color-white">
          When working with you on a workshop for your team, we will go over
          your goals, how many people you would like to create the workshop for,
          and how long you would like the workshop to be.
          <Br />
          Typically, we recommend a workshop to range between 1 to 3 hours, with
          a maximum of 200 people. If it's a longer workshop, we will
          incorporate a 10-minute break at the halfway point. If it's a larger
          group, we will recruit assistant instructor(s) to help faciliate some
          exercises in breakout groups, and reconvene as a larger group to
          debrief on the exercises. We can tailor the workshop to be in-person,
          or virtual.
          <Br />
          Here's an example 1-hour workshop tailored for leadership development,
          for a team of 15-20 people:
          <Br />
          <ul class="indent-list">
            <li>
              <b>Introduction (3 min)</b>
            </li>
            <p>
              Everyone gathers in a circle. We introduce improv, introduce
              ourselves, and go over the{" "}
              <Link
                class="format-word-link"
                to="/Blog/fostering-inclusive-environment"
                target="_blank"
                rel="noopener noreferrer"
              >
                guidelines
              </Link>{" "}
              for the session.
            </p>
            <li>
              <b>Warm Ups (7 min)</b>
            </li>
            <p>
              {" "}
              Improv warm ups allow us to stretch our minds and focus on the
              present, similar to how one would stretch before going on a run.
              We will probably play 2-4 warm up games. These games are meant to
              be quick and simple. with a quick debriefing afterwards.
              <Br />
              <p class="indent-list">Example warm ups:</p>
              <ul class="indent-list">
                <li>
                  Go around the circle, say your name and if the last three
                  months of your life was a movie title, what would it be?
                </li>
                <li>
                  Group Walk. Everyone walks around in a circle. Our goal is to
                  pay attention and walk like everyone else in the group. One
                  person might slowly introduce a different action, like raising
                  the arms while walking - everyone else must pick up this
                  change and copy. Anyone can introduce a change in walking
                  style, everyone else must follow. The trick is to be in sync
                  so that it's not noticeable who introduced the change.
                  Debrief: in a team setting, we're both leading and following,
                  and we need to pay attention to what other people are doing.
                  <Br />
                </li>
              </ul>{" "}
              <li>
                <b>What is Leadership? (5 min)</b>
                <br />
                We spend 2-3 minutes as a group discussing what makes a great
                leader. Participants will give answers, and it will be
                interactive for a few minutes. Afterwards, summarize participant
                responses, and then segway into exercises. We can all agree
                leaders have physical presence, problem solving skills,
                effective communication and confidence. The next exercises will
                focus on these features.
              </li>
              <Br />
              <li>
                <b>Lead with Different Body Parts (5 min) </b>
                <br />
                Everyone walks around the room in a random fashion. The
                facilitator calls out a body part, such as "nose." Everyone must
                walk with their nose first. After 10-15 seconds, the facilitator
                calls out a different body part, such as "chin." Everyone must
                walk with their chin out first. And so forth with different body
                parts. At the end of the exercise, quickly debrief: How did
                leading with different body parts make us feel? Did some
                postures make us feel more confident than others? The point of
                the exercise is to make us aware of our physicality. The way we
                walk and our posture affects how people see us, and how we see
                ourselves.
                <Br />
              </li>
              <li>
                <b>Problem Solving Exercise (10 min) </b> <br />
                Everyone gets into two lines. Two people up. Each person takes
                turns stating a random preconceived problem, and item. Then,
                each person solves the other person's problem with the item they
                have. For example: <br />
                Person A: "I have an apple, and I need to clean my car." <br />
                Person B: "I have a ukelele, and I need to finish my report."{" "}
                <br />
                Person A: "Oh! Well you can eat the apple, and have the energy
                to finish your report!" <br />
                Person B: "And I'll play you a song on the ukelele to put you in
                a good mood while you clean your car!" <br />
                At the end of the exercise, debrief: How did this exercise make
                you feel? Was it hard to both listen, and come up with a
                solution with what you have? Participants will notice that it's
	        difficult to listen!
	        That can often be the case in real life - we have to juggle 
	        multiple things and thoughts at the same time, focusing on 
	        listening and really hearing the problem helps channel our creative solutions. 
                <Br />
              </li>
              <li>
                <b>Ask an Expert (25 min)</b>
                <br />
                Each person takes a turn being on stage and interviewed by the
                group (use a timer). The person will pretend to be an expert in
                a field determined by the workshop participants. If you don't
                know the answer, just roll with it! For example: <br />
                Facilitator: And joining us today is the World's Expert on
                Garlic Farming, Dr. Garlic. We're all wondering, how did you get
                into garlic farming? <br />
                Dr. Garlic: Yes sure, it runs in my family you see... (etc.){" "}
                <br />
                Audience member raises hand: Can you comment on the new species of 
	        garlic you recently developed?
                <br />
                Dr. Garlic: Oh yes, the new species Magic Garlic is amazing. It 
	        grows under any conditions, doesn't need pesticides, and has all 
	        the nutritional values you need (etc.) <br />
                After the exercise, debrief: How did this exercise make you
                feel? What did you like about it? What were the challenges? The
                point of the exercise is to give everyone a chance to be in the
                spotlight, and to answer questions in a confident manner. Participants
	        will find joy in seeing everyone's unique approach to their characters.
                <Br />
              </li>
              <li>
                <b>Debrief the Session (5 min)</b>
                <p />
                Summarize how the exercises demonstrated the physicality,
                listening and problem solving, and confidence / communication
                skills of a leader. There's no "right" way to be a leader. 
	        We all have our own styles! 
	        Thank everyone for their time. If there is
                time, we could end with another short exercise.
              </li>
            </p>
          </ul>
          <p>
            This is just one example of a customized workshop tailored towards
            leadership development. We would love hear from you and work with
            your team to design specific workshops for your team goals. <br />
            Please contact: lili @ kaboomimprov . com
          </p>
          <Br />
          <Br /> <Br />
        </p>
      </div>
      <Footer />
    </section>
  );
}

export default ZBlog_CustomizedWorkshop;
