import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

function NavBar() {
  function NavBarButton(props) {
    return (
      <div>
        <li class="nav-item">
          <a href={props.hash} class="nav-link navbar-custom">
            {props.buttonStr}
          </a>
        </li>
      </div>
    );
  }

  return (
    <div>
      {/* Nav bar */}

      <nav
        class="navbar navbar-expand-lg navbar-dark navbar-custom"
        style={{ margin: "-20px 0 -20px 0" }}
      >
        <a class="navbar-brand" href="/">
          <p style={{ "margin-top": "20px" }}>
            <img
              id="navbar-logoandname"
              src="/images/improv_favicon.png"
              text="hi"
            />
            {/* <p id="star">★</p>  */} Kaboom Improv
          </p>
        </a>

        {/* <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
        >
          <span class="navbar-toggler-icon"></span>
        </button> */}

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav ml-auto">
            {/* <NavBarButton
              buttonStr="Individuals"
              hash="#section-upcomingEvents"
            />
            <NavBarButton buttonStr="Organizations" hash="#section-orgImprov" />
            <NavBarButton buttonStr="About" hash="#section-aboutLili" /> */}
            {/* <li class="nav-item">
              <Link class="nav-link navbar-custom" to="/">
                Home
              </Link>
            </li> */}
            {/* <li class="nav-item">
              <Link class="nav-link navbar-custom" to="/Blog">
                Blog
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>

      <section style={{ width: "100%", height: "10px" }}></section>
    </div>
  );
}

export default NavBar;
