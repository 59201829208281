import React from "react";
import { Link } from "react-router-dom";

function StartLine() {
  return (
    <div>
      <section id="start-line">
        <div> </div>
      </section>
    </div>
  );
}

export default StartLine;
