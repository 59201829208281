import React from "react";

import Footer from "./Footer";

function ZBlog_Fostering(props) {
  return (
    <section class="section-BlogArticle">
      <div class="div-BlogArticle">
        <h2 class="center-text text-color-white">
          {" "}
          Fostering an Inclusive Environment
        </h2>
        <p class="p-BlogArticle text-color-white">
          The most important part of facilitating an improv workshop is ensuring
          everyone is comfortable and having a good time.
          <br />
	  <br />
          Through our years of experience, we have found that setting the
          following guidelines before each session helps foster an
          inclusive environment and positive mood and vibe:
          <br />
          <br />
          <ul class="indent-list">
            <li>
              <b>Have fun!</b>
            </li>
            <p>
              The main goal is to let loose and have fun. If you leave this
              workshop with one thing, it's that we hope you had fun!
            </p>
            <li>
              <b>Keep things PG</b>
            </li>
            <p>
              {" "}
              Many of our activities involve interacting with other people. Because people come from all backgrounds, let's keep the content
              of our scenes PG.{" "}
            </p>
            <li>
              <b>Respect personal space</b>
            </li>
            <p>
              {" "}
              Because we all have different comfort level with touch, let's
              respect each other's personal space and keep our hands to
              oursevles.
            </p>
          </ul>
          In the extremely rare event that we accidentally cross one of the
          guidelines that's ok, we all make mistakes.
          <br />
          <br />
          If you realize you said or did something awkward, or if someone tells
          you, "hey, I don't like that", just quickly
          apologize. "Oh my gosh, I am so sorry. I'm going to be very mindful of
          this going forward." And we can move on. You don't have to
          over-explain why you did it, or emphasize you didn't do it on purpose
          - we already know that it was accidental. It's the ability to learn from our actions that's important.
          <br />
          <br />
          Trust and have faith that everyone is kind and putting their best foot
          forward. We are all here to be supportive and empathetic of each
          other!
	  <br />
	  <br /> 
	  Outside of our guidelines, if any behavior falls under the category of harassment or threat, you will be immediately asked to leave the workshop. 
	  <br/>
	  <br/>
        </p>
      </div>
      <Footer />
    </section>
  );
}

export default ZBlog_Fostering;
