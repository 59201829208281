import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import NavBar from "./components/NavBar";
import Blog from "./components/Blog";
import Home from "./components/Home";

import ZBlog_Fostering from "./components/ZBlog_Fostering";
import ZBlog_CustomizedWorkshop from "./components/ZBlog_CustomizedWorkshop";

function App() {
  return (
    <Router>
      <div>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Blog" element={<Blog />} />
          <Route
            exact
            path="/Blog/fostering-inclusive-environment"
            element={<ZBlog_Fostering />}
          />
          <Route
            exact
            path="/Blog/customized-workshop"
            element={<ZBlog_CustomizedWorkshop />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

{
  /*<iframe style="position: absolute; inset: 0px 0px 0px -522.833px; width: 1530.67px; height: 861px;" frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" title="Improv with Lili" width="640" height="360" src="https://www.youtube.com/embed/NDwSTUKjiZ8?autohide=1&autoplay=0&controls=0&enablejsapi=1&iv_load_policy=3&loop=0&modestbranding=1&playsinline=1&rel=0&showinfo=0&wmode=opaque&origin=https%3A%2F%2Fhyprov.com&widgetid=1"></iframe>*/
}

{
  /* ROW and 50-50  ------------
        <div class="row" style={{"color": "white", "border-style": "solid", "border-color": "green"}}>

          <div class="col-lg-6" style={{"color": "white", "border-style": "solid", "border-color": "red", "height": "600px"}}>
            <div class="" style={{"margin-left":"10%", "margin-top":"30%", "margin-right": "10%"}}>
                <p class="txt-static"> Unleash Your</p>
                <ul class="txt-dynamic">
                    <li><span>Creativity</span></li>
                    <li><span>Spontaneity</span></li>
                    <li><span>Genius</span></li>
                    <li><span>Inner Poet</span></li>
                    <li><span>Listening</span></li>
                    <li><span>Leadership</span></li>    
                    <li><span>Teamwork</span></li>                  
                </ul>
                <div class="txt-subtitle">
                    Fun and energetic improv instruction in the DMV area (DC, Maryland, Virginia), as well as virtually.
                </div>
            </div>

          </div>

          <div class="col-lg-6" style={{"height":"600px"}}>

          </div>


        </div>
        */
}
