import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

function AboutImprov() {
  return (
    <div>
      <section
        id="section-aboutImprov"
        style={{ "background-color": "#141518" }}
      >
        <div id="div-aboutImprov">
          <h1 class="text-color-white">
            {" "}
            Our Workshops Are Designed Using Improv Exercises
          </h1>
          <br />
          <p class="text-color-white">
            {" "}
            You might be familiar with improv in the context of improv comedy,
            or musical improvisation.
            <br />
            <br />
            Improv is so much more than that! Originating in theater, improv is
            a collection of mental exercises that stretch your mind and prepare
            it to be creative and adaptable.
            <br />
            <br />
            Think of improv like yoga for the brain - it strengthens the neural
            connections that build our confidence and creativity, allowing us to
            communicate more effectively and see more opportunities.
            <br />
            <br />
            Our repertoire spans over 500 improv exercises, and we curate them
            to create workshops to fulfill your team's specific focus areas.
          </p>
          <br />

          {/* <div class="row">
            <div class="col-lg-6">
              <div class="center-pp">
                <h3 class="id-aboutImprov-def">
                  A form of theater where the characters, scene and plot are
                  made up on the spot{" "}
                </h3>
              </div>
            </div>
            <div class="col-lg-6 add-padding">
              <p>
                Improv is:
                <ul class="indent-list">
                  <li>Fun</li>
                  <li>Joyful</li>
                  <li>Reflective</li>
                </ul>
                What are you waiting for? Try it out!
              </p>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default AboutImprov;
