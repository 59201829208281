import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

function AboutImprov2() {
  return (
    <div>
      <section
        id="section-aboutImprov2"
        style={{ "background-color": "#141518" }}
      >
        <div id="div-aboutImprov2">
          <div class="row">
            <div class="col-lg-6">
              <div class="center-pp">
                <h1 class="aboutImprov-text">
                  We Create Customized Workshops Based on Your Needs
                </h1>
                <p class="aboutImprov-text">
                  We design and teach team building and professional development
                  workshops for businesses, government and non-profit
                  organizations. Our workshops will expand and strengthen your
                  team's morale and connection to create sustainable
                  organizational impact.
                  <br />
                  <br />
                  Our workshops can be customzied for:
                  <ul>
                    <li>Leadership Development</li>
                    <li>Team Building</li>
                    <li>Communication and Creativity</li>
                    <li>Personal and Professional Development</li>
                    <li>Diversity, Equity and Inclusion</li>
                    <li> Self-Care and Well-Being</li>
                    <li>
                      Addressing Trauma and Chronic Stress in the Work Place
                    </li>
                  </ul>
                  Whether you're planning a retreat and need a full day's
                  programming, or looking for a quick 1-hour workshop, we will
                  provide you with a workshop your team will love.
                  <br />
                  <br />
                  Schedule a time to speak with us here:
                </p>
              </div>
            </div>
            <div class="col-lg-6 add-padding">
              <img
                id="action-photo"
                src="./images/improv-action.png"
                alt="Improv in action"
              ></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AboutImprov2;
