import React from "react";

function AboutLili() {
  function Teacher(props) {
    return (
      <div class="row add-margin-50 border-pink">
        <div class="col-lg-6">
          <img
            id="profile-photo"
            src={props.imageSrc}
            alt={props.imageAlt}
          ></img>
        </div>
        <div class="col-lg-6">
          <p class="aboutImprov-text">
            <br />
            <b style={{ color: "#ef3fff" }}>{props.name}</b>
            {props.work}
            <br />
            <br />
            {props.personal}
            <br />
            <br />
            Contact her at:
            <br />
            {props.contact}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <section id="section-aboutLili" style={{ "background-color": "#141518" }}>
        <div id="div-aboutLili">
          <h1 class="center-text text-color-white">Our Founders</h1>
          <Teacher
            imageSrc="./images/pic-shelby2.png"
            imageAlt="Shelby Picture"
            name="Shelby French (she/her)"
            work=" has used improv skills and techniques in various professional 
            and cultural contexts for 25 years. After earning a degree in 
            clinical social work from the University of Chicago, she worked in over 25 countries, 
            focusing on positive youth development, women's and girls' empowerment, 
            and the prevention and treatment of trauma. Shelby has extensive experience 
            performing and training in improv and sketch writing at Whole World Theatre in Atlanta, 
            and Second City and IO in Chicago. Through her work, she has trained hundreds of 
            professionals, organizations, and communities, utilizing improv as a means of 
            building resilience, promoting healing, and enhancing organizational performance.
            "
            personal="Shelby lives with her 15-year-old son and two unruly hounds, Astro and Rosie, 
            in Takoma Park, MD. Her life motto is “find and live your authentic self.”"
            contact="shelby @ kaboomimprov . com"
          />
          <Teacher
            imageSrc="./images/pic-lili2.png"
            imageAlt="Lili Picture"
            name="Lili Cai (she/her)"
            work=" has been teaching improv for 8 years in the New York and DC
                area as well as virtually. She studied with UCB's Patrick Noth
                and Philly Improv Theater's Frank Farrell. Lili was also trained
                in teaching leadership workshops from Cornell University. She
                loves merging the two worlds, and her favorite part of improv is
                how simple exercises can be both joyful and insightful. Lili started
                the Graduate Improv Group at Princeton University in 2015, a group
                that is still growing strong today."
            personal="Outside of improv, Lili has a PhD in neuroscience from Princeton
            University, and loves a hearty home cooked meal.  
               Her life motto is “have fun!”"
            contact="lili @ kaboomimprov . com"
          />
        </div>
      </section>
    </div>
  );
}

export default AboutLili;
