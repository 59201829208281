import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import Testimonials from "./Testimonials";

import Footer from "./Footer";
import BlogArticle from "./BlogArticle";

function Blog() {
  return (
    <section id="section-blog">
      <div id="div-blog">
        <h3 class="center-text text-color-white">Blogs</h3>

        <Footer />
      </div>
    </section>
  );
}

export default Blog;
