import React from "react";
import { Link, generatePath } from "react-router-dom";

function Testimonials() {
  function Testimonial(props) {
    let activeVar = props.active;
    activeVar = " ".concat(activeVar);
    let generateClasses = "carousel-item container-fluid";
    generateClasses = generateClasses.concat(activeVar);

    return (
      <div class={generateClasses}>
        <p
          style={{
            "margin-left": "10%",
            "margin-right": "10%",
            "margin-top": "5%",
          }}
        >
          {props.review}
        </p>
        <p style={{ "margin-left": "10%", "margin-right": "10%" }}>
          <em>-- {props.name}</em>
        </p>
      </div>
    );
  }

  return (
    <div>
      <section>
        <div class="test-div" style={{ margin: "10% 5% 0% 5%" }}>
          <h1 class="center-text">Testimonials</h1>
          <div
            id="testimonial-carousel"
            class="carousel slide"
            data-ride="carousel"
          >
            {/* indicators */}
            <div
              class="carousel-inner"
              style={{
                height: "450px",
                "margin-top": "0%",
                "margin-bottom": "0%",
                "border-color": "red",
              }}
            >
              <Testimonial
                review="Kaboom Improv is a blast! I had so much fun and everything was explained so well."
                name="Morgan O., Silver Spring, MD"
                active="active"
              />
              <Testimonial
                review="I went to Lili's Improv Class because I was intrigued.  I left with team building activities, learning experiences that cross cultural divides, and memories of fun and laughter.  Lili introduces improv to audiences at all levels in encouraging and engaging ways that leads to improved team communications, increased trust between team members, self-discovery and fun."
                name="Catherine C., Instructional Designer, International Humanitarian Organization"
                active=""
              />
              <Testimonial
                review="I've always wanted to try improv, and am so glad I did. Lili's
                  classes were phenomenal and I loved what we came up with as a
                  team."
                name="Theresa B., Washington, DC"
                active=""
              />
              <Testimonial
                review="I was pleasantly surprised by improv and voice workshop. 
                Instructor Lili was great, made me feel comfortable and spontaneous.
                 My fear of public speaking dissipated within minutes due to her demeanor and presence."
                name="Nino Z., Alexandria, VA"
                active=""
              />
              <Testimonial
                review="Improv makes you confident, creative and is just hella fun! Life is sadder without improv."
                name="Logan Z., Silver Spring, MD"
                active=""
              />
              <a
                class="carousel-control-prev"
                href="#testimonial-carousel"
                role="button"
                data-slide="prev"
              >
                <span class="glyphicon glyphicon-chevron-left"></span>
                <span class="carousel-control-prev-icon"></span>
              </a>
              <a
                class="carousel-control-next"
                href="#testimonial-carousel"
                role="button"
                data-slide="next"
              >
                <span class="carousel-control-next-icon"></span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Testimonials;
